<template>
  <HMModalContainer :isStudioPage="true">
    <StudentLogin :onLoginFn="onLogin" />
  </HMModalContainer>
</template>

<script lang="ts">
import StudentLogin from '@/components/signup/StudentLogin.vue';

export default {
  name: 'StudioLogin',
  components: {
    StudentLogin,
  },
  setup() {
    definePageMeta({
      name: 'studio-login',

      isStudio: true,
    })
  },
  methods: {
    onLogin() {
      this.$router.push({ name: 'studio-dashboard' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

:deep(.login) {
  .hm-modal-header {
    display: none !important;
  }
  @include media-breakpoint-up(sm) {
    h1 {
      margin-top: 0 !important;
    }
  }
}
</style>
